import { useEffect } from "react";
import {
  useLocation // useNavigate
} from "react-router";

const ScrollHandler = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      let intervalTimer = null;
      let timeOut = null;
      intervalTimer = setInterval(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          timeOut = window.setTimeout(() => {
            const rect = element.getBoundingClientRect();
            const offsetTop = window.pageYOffset + rect.top;

            window.scrollTo({
              behavior: "smooth",
              top: offsetTop - 50
            });
          }, 400);
          clearInterval(intervalTimer);
        }
      }, 500);

      return () => {
        clearInterval(intervalTimer);
        clearTimeout(timeOut);
      };
    }
    window.scrollTo(0, 0);
    return undefined;
  });

  // TODO: add gtag logic navigate
  // useEffect(() => {
  //   const gtag = window.gtag;
  //   if (history.action === "PUSH" && typeof gtag === "function") {
  //     // console.log('sending page event', window.location);
  //     gtag("config", "UA-117695765-2", {
  //       page_location: window.location.href,
  //       page_path: window.location?.pathname
  //     });
  //   }
  // }, [navigate]);

  return undefined;
};

export default ScrollHandler;
