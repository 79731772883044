import { useTranslation } from "react-i18next";

import StatusBar from "../StatusBar/StatusBar";

import styles from "./OrderCard.module.scss";

const OrderHeaderCard = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.orderCard} ${styles.headerCard}`}>
      <div className={styles.left}>
        <span className={styles.orderTypeIcon}></span>
        <span className={styles.orderId} style={{ marginLeft: 0 }}>
          {t("order")}
        </span>
        <span className={styles.orderId}>{t("order")} ID</span>
        <span className={styles.description}>{t("description")}</span>
      </div>
      <div className={styles.right}>
        <span>{t("start")}</span>
        <span>{t("end")}</span>
        <StatusBar showStatus={false} statusText="Complete" />
      </div>
    </div>
  );
};

export default OrderHeaderCard;
