import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { updateNotification } from "../../api/";
import { Clock } from "../../assets/icons";
import { useApi } from "../../context/context";
import useInterval from "../../hooks/useInterval";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";

import styles from "./Notifications.module.scss";

const ApiNotifications = (props) => {
  const { isUserVerified } = props;
  const { notifications, user, getNotifications } = useApi();
  const [localNotifications, setLocalNotifications] = useState([]);
  const { t } = useTranslation();

  const filterNotActive = (not) => not.Status < 2;

  const markAllAsSeen = () => {
    const updatedNotifications = notifications.map((not) => {
      not.Status = 2;
      return not;
    });
    setLocalNotifications(updatedNotifications?.filter(filterNotActive));
    // updateNotification(updatedNotifications[0], user?.token);
    updatedNotifications.forEach((not) => {
      updateNotification(not, user?.token);
    });
  };

  const markAsSeen = (not) => {
    const updatedNotification = {
      ...not,
      Status: 2
    };
    setLocalNotifications(
      localNotifications?.filter(
        (not_) => not_.NotificationID !== not.NotificationID
      )
    );
    updateNotification(updatedNotification, user?.token);
  };

  useInterval(async () => {
    // console.log("fetching notifications...");
    await getNotifications();
  }, 10000);

  useEffect(() => {
    if (notifications?.length > 0) {
      setLocalNotifications(notifications?.filter(filterNotActive));
    } else {
      setLocalNotifications([]);
    }
    // setLocalNotifications(notifications?.filter(filterNotActive));
  }, [notifications]);

  // console.log("notifications", notifications);

  return isUserVerified ? (
    <li className={`${styles.notifications}`}>
      <div className={`${styles.notificationMenuItem}`}>
        <Clock className={styles.clock} />
        {localNotifications.length > 0 && (
          <span className={styles.count}>{localNotifications?.length}</span>
        )}
        {t("toolbar.notifications")}{" "}
      </div>
      <NotificationDropdown
        notificationsList={localNotifications.filter((not) => not.Status < 2)}
        setAllAsSeens={() => {
          // console.log("setting all to seen");
          markAllAsSeen();
        }}
        markAsSeen={markAsSeen}
      />
    </li>
  ) : (
    <li></li>
  );
};

export default ApiNotifications;
