const niceKeys = (key) => {
  if (!key) {
    return "";
  }

  const spaceKey = key.replace(/([A-Z])/g, " $1").trim();

  return capitalizeFirstLetter(spaceKey.replace(/I D/g, "ID"));
};

function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default niceKeys;
