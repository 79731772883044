import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Finished, NotOnGoing, OnGoing, TimeFlag } from "../../assets/icons";
import {
  cipDateFormat,
  isValidDate,
  minimizeTimeSpan,
  parseChangelog
} from "../../utils";
import StatusBar from "../StatusBar/StatusBar";
import ThumbMap from "../ThumbMap/ThumbMap";
import Truck from "../Truck/Truck";

import styles from "./OrderCard.module.scss";

const OrderCard = (props) => {
  const { t } = useTranslation();
  const {
    Header = "-",
    ongoingOrder,
    orderType = "-",
    orderId,
    startDate = "",
    OrderStatus,
    endDate = "",
    order,
    to = { address: null, city: null },
    from = { address: null, city: null },
    last = false,
    first = false,
    pickupTime = "",
    deliveryTime = "",
    PickupWantedTimeFlag,
    DeliveryWantedTimeFlag
  } = props;

  const showStatusBar = false;
  const [orderStatus, setOrderStatus] = useState();
  const [isDelayed, setIsDelayed] = useState(false);
  const [parsedChangelog, setParsedChangelog] = useState(null);

  useEffect(() => {
    if (ongoingOrder.timelineStatus === 2) {
      return setOrderStatus(<OnGoing />);
    } else if (ongoingOrder.timelineStatus === 1) {
      return setOrderStatus(<NotOnGoing width="16" />);
    } else if (ongoingOrder.timelineStatus === 3) {
      return setOrderStatus(<Finished />);
    }
  }, [order, ongoingOrder]);

  useEffect(() => {
    setParsedChangelog(parseChangelog(OrderStatus?.[0]?.Changelog));
  }, [OrderStatus]);

  useEffect(() => {
    if (ongoingOrder.statusText === "Delayed") {
      setIsDelayed(true);
    } else {
      setIsDelayed(false);
    }
  }, [ongoingOrder.statusText]);

  const getTimeStart = () => {
    if (
      parsedChangelog?.["OrigTimePickup"] !== // Ensure OrigTimePickup is different from LatestTimePickup
        parsedChangelog?.["LatestTimePickup"] &&
      ongoingOrder?.statusText === "Changed" // Ensure the order status is "Changed"
    ) {
      return (
        <>
          <s>{parsedChangelog["OrigTimePickup"] || ""}</s>
          <br />
          {parsedChangelog["LatestTimePickup"] || ""}
        </>
      );
    }

    if (!isValidDate(startDate)) {
      return "Date is missing";
    }

    return (
      <>
        {cipDateFormat(startDate, true) || ""}
        {pickupTime && orderType === "Transport"
          ? `, ${minimizeTimeSpan(pickupTime)}`
          : null}
      </>
    );
  };

  const getTimeEnd = () => {
    if (
      parsedChangelog &&
      parsedChangelog["LatestTimeDelivery"] &&
      parsedChangelog["OrigTimeDelivery"] !==
        parsedChangelog["LatestTimeDelivery"] &&
      ongoingOrder.statusText === "Changed"
    ) {
      return (
        <>
          <s>{parsedChangelog["OrigTimeDelivery"]}</s>
          <br />
          {parsedChangelog["LatestTimeDelivery"]}
        </>
      );
    }

    if (!isValidDate(endDate)) {
      return "Date is missing";
    }

    return (
      <>
        {cipDateFormat(endDate, true)}
        {deliveryTime && orderType === "Transport"
          ? `, ${minimizeTimeSpan(deliveryTime)}`
          : null}
      </>
    );
  };

  // console.log("OrderStatus", OrderStatus);
  // console.log("parsedLog", parsedChangelog);

  return (
    <div
      data-component="OrderCard"
      className={`${
        ongoingOrder.timelineStatus === 2 && orderType === "Transport"
          ? styles.isTransport
          : styles.orderCard
      }
        ${first ? styles?.first : ""} ${last ? styles?.last : ""}
		${ongoingOrder.timelineStatus === 2 ? styles.isOngoing : ""}
    `}
    >
      <div className={styles.left}>
        <span className={styles.infoContainer}>
          <h4>{t("order")}</h4>
          <span className={styles.orderTypeIcon}>{orderStatus}</span>
          <h3>{orderType}</h3>
        </span>
        <span className={styles.infoContainer}>
          <h4>{t("order")} ID</h4>
          <span className={styles.orderId}>{orderId}</span>
        </span>
        <span className={styles.infoContainer}>
          <h4>{t("description")}</h4>
          <span className={styles.description}>{Header}</span>
        </span>
      </div>

      <div className={styles.right}>
        <span className={styles.infoContainer}>
          <h4>{t("start")}</h4>
          <span>
            {PickupWantedTimeFlag ? (
              <span alt="Booked time" title="Booked time">
                <TimeFlag />
              </span>
            ) : (
              ""
            )}
            {getTimeStart()}
          </span>
        </span>
        <span className={styles.infoContainer}>
          <h4>{t("end")}</h4>
          <span className="">
            {DeliveryWantedTimeFlag ? (
              <span alt="Booked time" title="Booked time">
                <TimeFlag />
              </span>
            ) : (
              ""
            )}
            {getTimeEnd()}
          </span>
        </span>
        <StatusBar
          showStatus={showStatusBar}
          statusText={ongoingOrder.statusText}
          // TODO check if has event log & then show icon (at the moment, this is too heavy)
          hasOderEvents={orderType === "Transport----"}
        />
      </div>
      {/* if is transport order = show map, and is ongoing  */}
      <>
        {ongoingOrder.timelineStatus === 2 && orderType === "Transport" ? (
          <div
            className={
              ongoingOrder.timelineStatus === 2 && orderType === "Transport"
                ? styles.expanded
                : styles.noContent
            }
          >
            <div className={`${styles.left} ${styles.bottom}`}>
              <div className={styles.truckContainer}>
                <Truck
                  isDelayed={isDelayed}
                  startTime={startDate}
                  endTime={endDate}
                />
              </div>
              <div className={styles.info}>
                <h4>Start</h4>
                <h5 className={styles.large}>
                  {cipDateFormat(startDate, false, true)}
                </h5>
                <p className={styles.address}>
                  {from.address} <br /> {from.city}
                </p>
              </div>
              <div className={styles.info}>
                <h4>Destination </h4>
                <h5 className={styles.large}>
                  {cipDateFormat(endDate, false, true)}
                </h5>
                <p className={styles.address}>
                  {to.address} <br /> {to.city}
                </p>
              </div>
            </div>
            <div className={styles.right}>
              <ThumbMap to={to} />
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default OrderCard;
