import { Navigate, Route, Routes } from "react-router";

import { useApi } from "./context/context";
import {
  Account,
  Home,
  Invoices,
  Login,
  NotFound,
  Order,
  Project,
  SetPassword,
  UnderMaintenance
} from "./pages";

const AppRoutes = () => {
  const { companyId, isVerified } = useApi();

  const isMaintenance = process.env.REACT_APP_MAINTENANCE === "true";

  if (companyId && isVerified) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Account />} />
        <Route path="/account/set-password" element={<SetPassword />} />
        <Route path="/account/set-password/:userId" element={<SetPassword />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/project/:projectId" element={<Project />} />
        <Route path="/order/:orderId" element={<Order />} />
        <Route
          path="/login"
          element={isMaintenance ? <UnderMaintenance /> : <Login />}
        />
        <Route path="/login-test" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/profile" element={<Account />} />
      <Route path="/account/set-password" element={<SetPassword />} />
      <Route path="/account/set-password/:userId" element={<SetPassword />} />
      <Route
        path="/login"
        element={isMaintenance ? <UnderMaintenance /> : <Login />}
      />
      <Route path="/login-test" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
