import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

import { SmallArrow } from "../../assets/icons";
import { useApi } from "../../context/context";

import styles from "./UserDetails.module.scss";

const UserDetails = () => {
  const { logoutUser, user, company } = useApi();

  const { t } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    logoutUser();
  };

  const { FirstName, LastName, Email, Mobile, Id } = user || {};
  const { Name } = company || {};

  return (
    <div className={styles.userDetailsContainer}>
      <ul className={styles.userDetails}>
        <li>
          <p>{t("name")}</p>
          <p>
            {FirstName} {LastName}
          </p>
        </li>
        <li>
          <p>{t("email")}</p>
          <p>{Email}</p>
        </li>
        <li>
          <p>{t("mobile")}</p>
          <p>{Mobile}</p>
        </li>
        <li>
          <p>{t("Company")}</p>
          <p>{Name}</p>
        </li>
        <li>
          <p>{t("Password")}</p>
          <p>
            *******
            <Link to="/account/set-password">Change password</Link>
          </p>
        </li>
        <li>
          <p>{t("Id")}</p>
          <p>{Id || "Empty"}</p>
        </li>
      </ul>
      <Button
        variant="contained"
        color="primary"
        type="button"
        disableElevation
        onClick={handleClick}
        sx={{
          width: "140px",
          mt: styles.spacing,
          px: "1.1rem",
          py: ".4rem",
          borderRadius: "100px",
          textTransform: "none",
          boxShadow: "none"
        }}
      >
        <SmallArrow className={styles.smallArrow} />
        {t("logOut")}
      </Button>
    </div>
  );
};

export default UserDetails;
