import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useState } from "react";

import { FileList } from "../";
import { filesByOrderId } from "../../api";
import { useApi } from "../../context/context";
import { tabifyOrder } from "../../utils";
import DetailList from "../DetailList/DetailList";
import OrderEvents from "../OrderEvents/OrderEvents";

import styles from "./OrderTabs.module.scss";
import TabPanel from "./TabPanel";

// import { useTranslation } from "react-i18next";

const OrderTabs = (props) => {
  // console.log("props", props);

  const { order, documents } = props;
  const { user } = useApi();
  const [value, setValue] = useState(0);
  // const { t } = useTranslation();
  const tabletWidth = useMediaQuery("(max-width:600px)");
  const [tabbifiedOrder, setTabbifiedOrder] = useState([]);
  const [orderDocuments, setOrderDocuments] = useState([]);
  // console.log(invoices);
  const fetchOrderFiles = useCallback(async () => {
    const filesList = await filesByOrderId(order.OrderID, user?.token);

    const modifiedDocumentsList = documents
      .map((file) => {
        return {
          ...file,
          file: file.FullName,
          Url: `Transport_pod/pod-${file.OrderID}.pdf`,
          columns: {
            type: "Signature",
            order: file.OrderID
          }
        };
      })
      .filter((file) => file.OrderID === order.OrderID);

    const modifiedfilesList = filesList.map((file) => {
      return {
        ...file,
        file: file.FileNameOrg,
        Url: file.FilenameSaved,
        columns: {
          type: file.Type,
          order: file.OrderID
        }
      };
    });

    setOrderDocuments([...modifiedDocumentsList, ...modifiedfilesList]);
  }, [documents, order.OrderID, user?.token]);

  useEffect(() => {
    fetchOrderFiles();
  }, [order, documents, fetchOrderFiles]);

  useEffect(() => {
    setTabbifiedOrder(tabifyOrder(order));
  }, [order]);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createTabs = () => {
    return tabbifiedOrder?.map((tab, i) => {
      return (
        <Tab
          key={i}
          label={tab.title}
          {...a11yProps(i)}
          sx={{ textTransform: "unset", color: "secondary.main" }}
        />
      );
    });
  };
  const createTabLists = () =>
    tabbifiedOrder?.map((data, i) => {
      const { title, content } = data;

      switch (title) {
        case "Documents":
          return (
            <TabPanel value={value} index={i} key={i}>
              <FileList
                order={order}
                canUpload={false}
                list={orderDocuments}
                showNotes
                updateFileList={() => {
                  fetchOrderFiles();
                  return true;
                }}
              />
            </TabPanel>
          );

        case "Order events":
          return (
            <TabPanel value={value} index={i} key={i}>
              <OrderEvents order={order} />
            </TabPanel>
          );

        default:
          return (
            <TabPanel value={value} index={i} key={i}>
              <div className={styles.listTabContainer}>
                <div>
                  {" "}
                  {content
                    ?.filter((a) => a.position !== "right")
                    .map((theList, index) => {
                      return (
                        <DetailList
                          title={theList?.title}
                          list={theList?.list}
                          key={index}
                        />
                      );
                    })}
                </div>
                <div>
                  {content
                    ?.filter((a) => a.position === "right")
                    .map((theList, index) => {
                      return (
                        <DetailList
                          title={theList?.title}
                          list={theList?.list}
                          key={index}
                        />
                      );
                    })}
                </div>
              </div>
            </TabPanel>
          );
      }
    });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "tertiary.main",
          display: "flex",
          justifyContent: tabletWidth ? "flex-start" : "center"
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs"
          variant="scrollable"
          scrollButtons={false}
        >
          {createTabs()}
        </Tabs>
      </Box>

      {createTabLists()}
    </Box>
  );
};

export default OrderTabs;
