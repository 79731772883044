const getInvoicesWithProjectId = (invoices, projectId) => {
  if (!invoices) {
    return [];
  }

  if (!projectId) {
    return [];
  }

  // get Invoices for projects projectId
  const projectInvoicesData = invoices?.filter((item) => {
    const { ProjectIDs } = item;

    if (!ProjectIDs) {
      return false;
    }

    const projectIds = ProjectIDs.split("\r");

    const hasProjectId = projectIds
      .map((str) => parseInt(str))
      .includes(projectId);

    return hasProjectId;
  });

  return projectInvoicesData;
};

export default getInvoicesWithProjectId;
