import { Typography } from "@mui/material";

const RenderLoginContainer = ({ title, children }) => (
  <div className="login-container centered">
    <Typography variant="h1" color="primary.main">
      {title}
    </Typography>
    {children}
  </div>
);

export default RenderLoginContainer;
