import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router";

import OrderDetailsInfo from "../../components/OrderDetailsInfo/OrderDetailsInfo";
import OrderTabs from "../../components/OrderTabs/OrderTabs";
import { useApi } from "../../context/context";
import {
  cipDateFormat,
  getOrderDates,
  getOrderStatus,
  getOrderType
} from "../../utils";

const Order = () => {
  const { orderId } = useParams();

  const { t } = useTranslation();

  const { getOrderByOrderId, documents, projects } = useApi();

  const [order, setOrder] = useState();
  const [orderType, setOrderType] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [orderStatus, setOrderStatus] = useState({ statusText: "" });

  useEffect(() => {
    const startDate = getOrderDates(order).startDate;
    const endDate = getOrderDates(order).endDate;

    setStart(cipDateFormat(startDate, true));
    setEnd(cipDateFormat(endDate, true));
    setOrderStatus(getOrderStatus(order));
  }, [order]);

  useEffect(() => {
    setOrder(getOrderByOrderId(orderId));
  }, [getOrderByOrderId, orderId, projects]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const _documentList = await documents([order], order?.ProjectID);
      setDocumentList(_documentList);
    };
    if (order) {
      setOrderType(getOrderType(order));
      fetchDocuments();
    }
  }, [documents, order]);

  return (
    <>
      {order && (
        <>
          <Link className="back-button" to={`/project/${order.ProjectID}`}>
            {t("back")}
          </Link>

          <div className="breadcrumb">
            <Link to={`/project/${order.ProjectID}`}>
              {t("project")} {order.ProjectID} / {t("order")} {orderId}
            </Link>
          </div>

          <Typography variant="h1" color="primary.main">
            {order.Header || order.OrderID}
          </Typography>

          <OrderDetailsInfo
            orderType={orderType}
            startDate={start}
            endDate={end}
            orderStatus={orderStatus}
          />

          <OrderTabs documents={documentList} order={order} />
        </>
      )}
    </>
  );
};

export default Order;
