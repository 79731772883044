import { Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router";

import mtabApi from "../../api/mtabApi";
import { SmallArrow, Spinner } from "../../assets/icons";
import { VerifyForm } from "../../components";
import { useApi } from "../../context/context";

import "./style.scss";

function SetPassword() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { user, logoutUser } = useApi();
  const [step, setStep] = useState("verify");
  const [email, setEmail] = useState("");
  const [verifiedDetails, setVerifiedDetails] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitUserId = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // 1. Call verify API to send user an sms
    try {
      await mtabApi({
        url: `/resetpassword/${email}`,
        method: "GET"
      });

      setStep("MFA");
    } catch (error) {
      console.error(error);
      setEmailError(error?.response?.data?.message || error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== newPasswordCheck) {
      setNewPasswordError("The password do not match.");
      return;
    }
    const { token } = verifiedDetails || {};
    if (!token) {
      setNewPasswordError("Time expired, reload page.");
    }

    try {
      await mtabApi({
        url: `api/UserAuthentication/SetNewUserPassword/${email}/${newPassword}`,
        method: "GET",
        headers: {
          Authorization: `token ${token}`,
          "content-type": "application/json"
        }
      });
      setStep("DONE!");
      setTimeout(() => {
        logoutUser(false);
      }, 3000);
    } catch (error) {
      console.error(
        "Could not send MFA error:",
        error?.response?.data || error
      );
      setNewPasswordError("Could not set new password, please try again.");
      return;
    }
  };

  useEffect(() => {
    if (userId) {
      setEmail(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (user?.Email) {
      setEmail(user.Email);
    }
  }, [user]);

  switch (step) {
    case "verify":
      return (
        <div className="set-password-container">
          <Typography variant="h1" color="primary.main">
            {userId && userId !== "" ? "Set password" : "Change password"}
          </Typography>
          <p>
            {userId && userId !== ""
              ? "Please confirm your e-mail to verify your account and set a new password."
              : "Please enter your e-mail to verify your account and set a new password."}
          </p>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem"
              }}
            >
              <Spinner />
            </div>
          ) : (
            <form onSubmit={handleSubmitUserId}>
              <TextField
                id="email-input"
                name="userid"
                label="E-mail"
                type="email"
                fullWidth
                variant="outlined"
                autoComplete="userid"
                required
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 5, backgroundColor: "white" }}
                error={!!emailError}
                helperText={email && emailError}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
                disabled={isLoading}
                sx={{
                  width: "140px",
                  mt: 1,
                  px: "1.1rem",
                  py: ".4rem",
                  borderRadius: "100px",
                  textTransform: "none",
                  boxShadow: "none"
                }}
              >
                <SmallArrow />
                {t("Next")}
              </Button>
            </form>
          )}
        </div>
      );

    case "MFA":
      return (
        <VerifyForm
          user={{ userId: email }}
          onSuccess={(data) => {
            setVerifiedDetails(data);
            setStep("setPassword");
          }}
        />
      );

    case "setPassword":
      return (
        <div className="set-password-container">
          <Typography variant="h1" color="primary.main">
            Set new password
          </Typography>
          <p>Enter and confirm a new password below.</p>
          <form onSubmit={handleSubmitPassword}>
            <TextField
              id="password-input"
              name="userid"
              label="New password"
              type="password"
              fullWidth
              variant="outlined"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required
              onChange={(e) => setNewPassword(e.target.value)}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              sx={{ mb: 5, backgroundColor: "white" }}
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
            <TextField
              id="password-check-input"
              name="password-check"
              label="Confirm new password"
              type="password"
              fullWidth
              variant="outlined"
              required
              value={newPasswordCheck}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => setNewPasswordCheck(e.target.value)}
              sx={{ mb: 5, backgroundColor: "white" }}
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              sx={{
                width: "140px",
                mt: 1,
                px: "1.1rem",
                py: ".4rem",
                borderRadius: "100px",
                textTransform: "none",
                boxShadow: "none"
              }}
            >
              <SmallArrow />
              {t("Submit")}
            </Button>
          </form>
        </div>
      );

    default:
      return (
        <div className="set-password-container">
          <Typography variant="h1" color="primary.main">
            Success
          </Typography>
          <p>
            Your new password is set. You will be redirected to{" "}
            <Link to="/logout">login form</Link>.
          </p>
        </div>
      );
  }
}

export default SetPassword;
