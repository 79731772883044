const formatDeliveryAddress = (address) => {
  if (!address) {
    return "";
  }

  if (!address.DeliveryZipCode) {
    return {
      Address: `${address.DeliveryAdress}<br />${address.DeliveryCity}<br />${
        address.DeliveryCountry || "SVERIGE"
      }`
    };
  } else {
    return {
      Address: `${address.DeliveryAdress}<br />${address.DeliveryZipCode} ${
        address.DeliveryCity
      }<br />${address.DeliveryCountry || "SVERIGE"}`
    };
  }
};

const formatPickupAddress = (address) => {
  if (!address) {
    return "";
  }

  if (!address.PickupZipCode) {
    return {
      Address: `${address.PickupAdress}<br />${address.PickupCity}}<br />${
        address.PickupCountry || "SVERIGE"
      }`
    };
  } else {
    return {
      Address: `${address.PickupAdress}<br />${address.PickupZipCode} ${
        address.PickupCity
      }<br />${address.PickupCountry || "SVERIGE"}`
    };
  }
};

export { formatDeliveryAddress, formatPickupAddress };
