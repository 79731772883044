import dayjs from "dayjs";

const isValidDate = (date) => {
  if (!date) {
    return false;
  }

  return dayjs(date).isValid();
};

export default isValidDate;
