import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";

import {
  DropDown,
  LoginForm,
  RenderLoginContainer,
  VerifyForm
} from "../../components";
import { useApi } from "../../context/context";

import "./style.scss";

function Login() {
  const { t } = useTranslation();
  const { user, isVerified, companyId } = useApi();
  const { Email } = user;

  const renderLeftContent = () => (
    <div className="login-left-content">
      <a
        href="https://mtab.eu"
        className="back-to-website button"
        rel="noopener noreferrer"
      >
        Back to website
      </a>
      <div className="text-content">
        <Typography variant="h2">Track your projects</Typography>
        <Typography variant="body1">
          Don&apos;t have access? Read more{" "}
          <a
            href="https://mtab.eu/about-us/mymtab"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .<br />
          <br />
        </Typography>
      </div>
    </div>
  );

  if (!Email) {
    // default login form
    return (
      <div className="login-split-container">
        {renderLeftContent()}
        <div className="login-right-content">
          <RenderLoginContainer title={t("login")}>
            <br />
            <br />
            <LoginForm />
          </RenderLoginContainer>
        </div>
      </div>
    );
  }

  if (!isVerified) {
    // verification form OTP
    return (
      <div className="login-split-container">
        {renderLeftContent()}
        <div className="login-right-content">
          <RenderLoginContainer title={t("verifyYourAccount")}>
            <VerifyForm user={user} />
          </RenderLoginContainer>
        </div>
      </div>
    );
  }

  if (!companyId) {
    return (
      <div className="login-single-container">
        <RenderLoginContainer title="Select Your Company">
          <Box>
            <p>
              Welcome! Please choose the company you&apos;d like to access for
              this session.
            </p>
            <p>To switch companies later, simply log out and sign back in.</p>
            <br />
            <br />
            <DropDown />
          </Box>
        </RenderLoginContainer>
      </div>
    );
  }

  // redirect to home
  return <Navigate to="/" />;
}

export default Login;
