import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";

import { verify } from "../../api";
import { useApi } from "../../context/context";

import styles from "./LoginForm.module.scss";

// import { TEST_USER } from "config";

const defaultValue = {
  // verificationCode: TEST_USER.password
  verificationCode: ""
};

const VerifyForm = (props) => {
  const { user, onSuccess } = props;

  const { setIsVerified, verifyUser, setState, errorMessage } = useApi();

  const [formValue, setFormValues] = useState(defaultValue);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValue,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await verify(
      {
        code: formValue.verificationCode,
        userId: user?.userId || user?.Email
      },
      setState
    );

    // api call
    if (res?.token) {
      setIsVerified(true);
      verifyUser(res);
      onSuccess && onSuccess(res);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.verificationForm}>
        <Box sx={{ m: styles.spacing }}>
          <p className={styles.infoMsg}>
            We have sent a message with a verification code to{" "}
            {user?.Mobile || "your phone number"}.
            {" Please enter the code below. "}
          </p>
        </Box>
        <TextField
          id="verification-input"
          name="verificationCode"
          label="Verification code"
          autoComplete="off"
          fullWidth
          variant="outlined"
          required
          type="text"
          value={formValue.verificationCode}
          onChange={handleChange}
          error={!!errorMessage}
          helperText={errorMessage}
          sx={{ backgroundColor: "white" }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disableElevation
          sx={{ mt: styles.spacing, p: 2, borderRadius: "100px" }}
        >
          Continue
        </Button>

        <Box sx={{ m: styles.spacing }}>
          <p className={styles.infoMsg}>
            Didn&apos;t get the code?
            <a href="/login"> Resend the code</a>
          </p>
        </Box>
      </form>
    </>
  );
};

export default VerifyForm;
