import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router";

import App from "./App/App";
import ApiContextProvider from "./context/context";
import LocalContextProvider from "./context/LocalContext";
import "./i18n";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalContextProvider>
        <ApiContextProvider>
          <App />
        </ApiContextProvider>
      </LocalContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
