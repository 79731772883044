import { niceKeys } from "../../utils";

import styles from "./DetailList.module.scss";

const DetailList = (props) => {
  const { title = "Summary", list = {} } = props;

  const detailKeys = Object.keys(list);

  const listContainer = detailKeys?.map((key, index) => {
    // remove empty, undefined values and arrays
    if (
      Array.isArray(list[key]) ||
      list[key] === null ||
      list[key] === undefined ||
      list[key] === ""
    ) {
      return null;
    }

    const formattedKey = niceKeys(key);

    return (
      <li key={index}>
        <p>{formattedKey}</p>
        <p dangerouslySetInnerHTML={{ __html: list[key] }}></p>
      </li>
    );
  });

  return (
    <div className={styles.detailListContainer} data-component="DetailList">
      <h3>{title}</h3>
      <ul className={styles.detailList}>{listContainer}</ul>
    </div>
  );
};

export default DetailList;
