const withinHours = (date, hours = 24) => {
  if (!date) {
    return false;
  }

  const now = new Date();
  const end = new Date(date);
  const diff = end.getTime() - now.getTime();
  return diff < hours * 60 * 60 * 1000 && diff > 0;
};

// Return short time span if start and end are the same 16:00 - 16:00 -> 16:00
const minimizeTimeSpan = (span) => {
  if (!span) {
    return "";
  }

  const [start, end] = span.split(" - ");

  if (start === end) {
    return start;
  }

  return `${start} - ${end}`;
};

export { minimizeTimeSpan, withinHours };
