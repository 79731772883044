import getDateWithAddedTime from "./getDateWithAddedTime";
import isValidDate from "./isValidDate";

const formatTime = (time) => {
  if (!time) {
    return "";
  }

  const formattedTime = time?.length > 5 ? time?.substring(0, 5) : time;

  return formattedTime;
};

const getOrderDates = (order) => {
  if (!order) {
    return {
      startDate: null,
      endDate: null,
      pickupTime: "All day",
      deliveryTime: "All day"
    };
  }

  const {
    PlanedDateFrom,
    DeliveryDateTo,
    PlanedDateTo,
    PlanedTimeStart,
    PlanedTimeEnd,
    DeliveryTimeStart,
    DeliveryTimeEnd
  } = order?.OrderStatus?.[0] || {};

  // let startDate = PlanedDateFrom;
  // let endDate = DeliveryDateTo;

  const startDate = getDateWithAddedTime(PlanedDateFrom, PlanedTimeStart);
  let endDate = getDateWithAddedTime(DeliveryDateTo, DeliveryTimeEnd);

  if (!isValidDate(endDate)) {
    endDate = getDateWithAddedTime(PlanedDateTo, PlanedTimeEnd);
  }

  const pickupTime = [formatTime(PlanedTimeStart), formatTime(PlanedTimeEnd)]
    .filter((time) => time)
    .join(" - ");

  const deliveryTime = [
    formatTime(DeliveryTimeStart),
    formatTime(DeliveryTimeEnd)
  ]
    .filter((time) => time)
    .join(" - ");

  return {
    startDate: isValidDate(startDate) ? startDate : null,
    endDate: isValidDate(endDate) ? endDate : null,
    pickupTime: pickupTime || "All day",
    deliveryTime: deliveryTime || "All day"
  };
};

export default getOrderDates;
