import dayjs from "dayjs";
import "dayjs/locale/sv";

import isValidDate from "./isValidDate";

dayjs.locale("sv");

/**
 *
 * @param {*} date
 * @param {*} hideTime
 * @param {*} hideDate
 * @returns {string}
 */
const cipDateFormat = (
  date,
  hideTime = true,
  hideDate = false,
  shortForm = false
) => {
  if (!date) {
    return "";
  }

  if (!isValidDate(date)) {
    return "";
  }

  if (hideTime) {
    return dayjs(date).format("D MMM YYYY");
  }

  if (hideDate) {
    if (shortForm) {
      return dayjs(date).format("H");
    }
    return dayjs(date).format("HH:mm");
  }

  return dayjs(date).format("D MMM YYYY, HH:mm");
};

export default cipDateFormat;
