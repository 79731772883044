import { BASE_URL, IS_DEBUG } from "../config";

import mtabApi from "./mtabApi";

/**
 *
 * @param {string} id
 * @param {string} token
 * @returns {Promise} {{"ID": 1,"SignatureID": 2,"OrderID": 3,"CompanyCustomerID": 4,"ProjectID": 5,"FileName": "sample string 6","Url": "sample string 7","FullName": "sample string 8","Created": "2022-09-19T10:25:53.8527416+02:00","CreatedBy": "sample string 10","Modified": "2022-09-19T10:25:53.8527416+02:00","ModifiedBy": "sample string 12","SourceCreated": "2022-09-19T10:25:53.8527416+02:00","SourceLastChange": "2022-09-19T10:25:53.8527416+02:00"}}
 */
const getSignaturesOnProjectId = async (id, token) => {
  /*
    - http://81.228.120.21/api/Signature/GetSignaturesOnCompanyCustomerID/12228
    - http://81.228.120.21/api/Signature/GetSignaturesOnProjectID/911439
    - http://81.228.120.21/api/Signature/GetSignaturesOnProjectID/911580
  */
  try {
    const res = await mtabApi({
      url: `/api/Signature/GetSignaturesOnProjectID/${id}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("getSignaturesOnProjectId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "getSignaturesOnProjectId error:",
      error?.response?.data?.message || error
    );
    return [];
  }
};

/**
 *
 * @param {string} id
 * @param {string} token
 * @returns {Promise}
 */
const getOrderStatusLogsOnOrderID = async (id, token) => {
  try {
    const res = await mtabApi({
      url: `/api/OrderStatusLog/GetOrderStatusLogsOnOrderID/${id}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("GetOrderStatusOnOrderID response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "GetOrderStatusOnOrderID error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {string} id
 * @param {string} token
 * @returns {Promise}
 */
const getImagesOnOrderId = async (id, token) => {
  // http://81.228.120.21/api/Image/GetImagesOnOrderID/4016365
  try {
    const res = await mtabApi({
      url: `/api/Image/GetImagesOnOrderID/${id}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("getImagesOnOrderId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "getImagesOnOrderId error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {string} id
 * @returns {string}
 */
const getImagesFromCDS = (id) => `${BASE_URL}/CDS/images/${id}`;

/**
 *
 * @param {string} url
 * @returns {string}
 */
const getContentFromCDS = (url) => {
  if (!url || url === "url") {
    return "";
  }
  const newurl = new URL(url);

  //  http://159.253.26.35/CDS/mtab-delivery/assets/uploads/images/image06052022120209.jpg
  // http://localhost:9000/CDS/mtab-delivery/assets/uploads/images/image06052022120209.jpg

  return `${BASE_URL}${newurl?.pathname}`;
};

/**
 *
 * @param {string} companyId
 * @param {string} token
 * @returns {Promise}
 */
const projectsByCompanyId = async (companyId, token) => {
  // http://81.228.120.21/api/Project/GetOnCompanyCustomerID/12228
  try {
    const res = await mtabApi({
      url: `/api/Project/GetOnCompanyCustomerID/${companyId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("projectsByCompanyId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "projectsByCompanyId error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {string} companyId
 * @param {string} token
 * @returns {Promise}
 */
const ordersByCompanyId = async (companyId, token) => {
  try {
    const res = await mtabApi({
      // http://81.228.120.21/api/Order/GetOrderOnCompanyCustomerID/12228
      url: `/api/Order/GetOrderOnCompanyCustomerID/${companyId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("ordersByCompanyId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "ordersByCompanyId error:",
      error?.response?.data?.message || error
    );

    return undefined;
  }
};

/**
 *
 * @param {string} projectId
 * @param {string} token
 * @returns {Promise}
 */
const filesByProjectId = async (projectId, token) => {
  try {
    const res = await mtabApi({
      // http://81.228.120.21/api/Order/GetOrderOnCompanyCustomerID/12228
      url: `/api/CIPFiles/GetOnProjectID/${projectId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("filesByProjectId  response:", res);

    // filters out 'status=2' is delete
    const data = res.data;
    const filesListFilteredDelete = data.filter(({ Status }) => Status !== 2);

    return filesListFilteredDelete;
  } catch (error) {
    console.error(
      "filesByProjectId error:",
      error?.response?.data?.message || error
    );

    return undefined;
  }
};
/**
 *
 * @param {string} OrderId
 * @param {string} token
 * @returns {Promise}
 */
const filesByOrderId = async (orderId, token) => {
  try {
    const res = await mtabApi({
      // http://81.228.120.21/api/Order/GetOrderOnCompanyCustomerID/12228
      url: `/api/CIPFiles/GetOnOrderID/${orderId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("filesByOrderId  response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "filesByOrderId error:",
      error?.response?.data?.message || error
    );

    return undefined;
  }
};
/**
 *
 * @param {string} companyId
 * @param {string} token
 * @returns {Promise}
 */
const invoicesByCompanyId = async (companyId, token) => {
  try {
    const res = await mtabApi({
      url: `/api/Invoice/GetInvoiceOnCustomerID/${companyId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("invoicesByCompanyId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "invoicesByCompanyId error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {string} companyId
 * @param {string} token
 * @returns {Promise}
 */
const notificationsByCompanyId = async (companyId, token) => {
  try {
    const res = await mtabApi({
      url: `/api/Notification/GetNotificationOnCompanyCustomerID/${companyId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    // if (IS_DEBUG) console.debug("notificationsByCompanyId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "notificationsByCompanyId error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {object} notificationObj
 * @param {string} token
 * @returns {Promise}
 */
const updateNotification = async (notificationObj, token) => {
  // Testing using formData
  const formData = new FormData();
  // formData.append("NotificationID", notificationObj?.NotificationID);
  // formData.append("CompanyCustomerID", notificationObj?.CompanyCustomerID);
  formData.append("ID", notificationObj?.ID);
  // formData.append("Message", notificationObj?.Message);
  // formData.append("SourceCreated", notificationObj?.SourceCreated);
  // formData.append("SourceLastChange", notificationObj?.SourceLastChange);
  // formData.append("ProjectID", notificationObj?.ProjectID);
  // formData.append("OrderID", notificationObj?.OrderID);
  formData.append("Status", notificationObj?.Status);

  // testing using json
  // const json = JSON.stringify(notificationObj);

  try {
    const res = await mtabApi({
      method: "post",
      url: "/api/Notification/SaveNotification",
      data: formData,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return res.data;
  } catch (error) {
    console.error(
      "SaveNotification error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @returns {Promise}
 */
const allContacts = async (token) => {
  try {
    const res = await mtabApi({
      url: `/api/SalesPerson/All`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("allContacts response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "allContacts error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

/**
 *
 * @param {Array} orders
 * @param {string} projectId
 * @param {string} companyId
 * @param {string} token
 * @returns {Promise}
 */
const getDocuments = async (orders, projectId, companyId, token) => {
  try {
    const orderIdString = orders?.map((order) => order?.OrderID).join(",");

    const urlString = `/documents?companyId=${companyId}${
      projectId ? `&projectId=${projectId}` : ""
    }&orderIds=${orderIdString}`;

    const res = await mtabApi({
      url: urlString,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("getDocuments response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "getDocuments error:",
      error?.response?.data?.message || error
    );
    return [];
  }
};

/**
 *
 * @param {string} data
 * @param {function} setState
 * @returns {Promise}
 */
const login = async (data, setState) => {
  try {
    const res = await mtabApi({
      method: "POST",
      url: "/user/login",
      data
    });

    if (IS_DEBUG) console.debug("login response:", res);
    setState((prev) => ({
      ...prev,
      errorMessage: ""
    }));
    return res.data;
  } catch (error) {
    console.error("login error:", error?.response?.data?.message || error);
    setState((prev) => ({
      ...prev,
      errorMessage: error?.response?.data?.message
    }));
    return undefined;
  }
};

/**
 *
 * @param {string} data
 * @param {function} setState
 * @returns {Promise}
 */
const verify = async (data, setState) => {
  try {
    const res = await mtabApi({
      method: "POST",
      url: "/user/verify",
      data,
      headers: {
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("verify response:", res);

    setState((prev) => ({
      ...prev,
      errorMessage: ""
    }));

    return res.data;
  } catch (error) {
    console.error("login error:", error?.response?.data?.message || error);
    setState((prev) => ({
      ...prev,
      errorMessage: error?.response?.data?.message
    }));
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @returns {Promise}
 */
const verifyToken = async (token) => {
  try {
    const res = await mtabApi({
      url: `/user/verifyToken`,
      method: "POST",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("verifyToken response:", res);

    return res.data;
  } catch (error) {
    console.error("logout error:", error?.response?.data?.message || error);
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @returns {Promise} void
 */
const logout = async (token) => {
  try {
    const res = await mtabApi({
      url: `/user/logout`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    console.info("logout", res);

    if (IS_DEBUG) console.debug("logout response:", res);
  } catch (error) {
    console.error("logout error:", error?.response?.data?.message || error);
  }
};

/**
 *
 * @param {string} companyId
 * @param {string} token
 * @returns {[{ ID: 24,"CompanyIDMTAB": 12228,"Name": "sample string 4","SalesPersonID": 10,"SalesPersonName": "sample string 11"}]}
 */
const getOnCompanyID = async (companyId, token) => {
  // http://81.228.120.21/api/Company/GetOnCompanyID/12228
  // stage
  // http://20.240.141.78/api/Company/GetOnCompanyID/12228
  try {
    const res = await mtabApi({
      url: `/api/Company/GetOnCompanyID/${companyId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("projectsByCompanyId response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "projectsByCompanyId error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

// http://localhost:44307/api/usercompanies/{userId}
/**
 *
 * @param {number} userId
 * @param {string} token
 * @returns {Promise}
 */
const getUserCompanies = async (userId, token) => {
  try {
    const res = await mtabApi({
      url: `/api/usercompanies/${userId}`,
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    if (IS_DEBUG) console.debug("getUserCompanies response:", res);

    return res.data;
  } catch (error) {
    console.error(
      "getUserCompanies error:",
      error?.response?.data?.message || error
    );
    return undefined;
  }
};

export {
  allContacts,
  filesByOrderId,
  filesByProjectId,
  getContentFromCDS,
  getDocuments,
  getImagesFromCDS,
  getImagesOnOrderId,
  getOnCompanyID,
  getOrderStatusLogsOnOrderID,
  getSignaturesOnProjectId,
  getUserCompanies,
  invoicesByCompanyId,
  login,
  logout,
  notificationsByCompanyId,
  ordersByCompanyId,
  projectsByCompanyId,
  updateNotification,
  verify,
  verifyToken
};
