import dayjs from "dayjs";

// http://localhost:9000/api/Order/GetOrderOnCompanyCustomerID/47281
/* old live data */
/*
PlanedDateFrom 2025-01-31T00:00:00
PlanedTimeStart 16:00:00
 */

/* new data */
/*
PlanedDateFrom 2025-01-31T00:00:00
PlanedTimeStart 4:00 PM
*/

const getDateWithAddedTime = (date, time) => {
  if (!date) {
    return "";
  }

  if (!dayjs(date).isValid()) {
    return "";
  }

  let timeArray = [];
  if (time) {
    timeArray = time.split(":");
  }

  const newDateString = dayjs(date)
    .set("hour", timeArray[0] || 0)
    .set("minute", timeArray[1] || 0)
    .set("second", timeArray[2] || 0)
    .format();

  return newDateString;
};

export default getDateWithAddedTime;
