import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const tabletWidth = useMediaQuery("(max-width:768px)");

  return (
    <div
      key={index}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4, pb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: tabletWidth ? "column" : "row",
              justifyContent: "space-between"
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
