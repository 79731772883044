import dayjs from "dayjs";

const parseChangelog = (input) => {
  if (!input) {
    return null;
  }

  const obj = {};
  if (input) {
    input.split("\r").forEach((line) => {
      if (typeof line === "undefined") return;
      const index = line.indexOf(":");
      const key = line.substring(0, index);
      const value = line.substring(index + 1);
      const [start, end] = value.trim().split(" - ");

      const startDate = dayjs(start, "YYYY-MM-DD HH:mm").format("DD MMM YYYY");
      const endDate = dayjs(end, "YYYY-MM-DD HH:mm").format("DD MMM YYYY");
      const startTime = dayjs(start, "YYYY-MM-DD HH:mm").format("H:mm");
      const endTime = dayjs(end, "YYYY-MM-DD HH:mm").format("H:mm");
      if (startDate === endDate) {
        obj[key.trim()] = `${startDate}, ${startTime} - ${endTime}`;
      } else {
        obj[key.trim()] = `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
      }
    });
  } else {
    return null;
  }
  return obj;
};

export default parseChangelog;
