import { orderBy } from "lodash";

const getSortedOrdersWithProjectId = (ordersData, ProjectID) => {
  if (!ordersData) {
    return [];
  }

  if (!ProjectID) {
    return [];
  }

  const projectOrders = ordersData?.filter(
    (item) => item?.ProjectID === parseInt(ProjectID)
  );

  const projectOrdersSorted = orderBy(
    projectOrders,
    ["OrderStatus.[0].PlanedDateFrom"],
    ["asc"]
  );

  return projectOrdersSorted;
};

export default getSortedOrdersWithProjectId;
