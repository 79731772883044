import axios from "axios";

const getContactPicture = async (name) => {
  if (!name) {
    return "";
  }

  const nameEncoded = encodeURIComponent(name);

  const src = await axios.get(
    `https://mtab.se/wp-json/wp/v2/kontakt?_embed&search=${nameEncoded}`
  );

  if (!src?.data?.[0]?._embedded?.["wp:featuredmedia"]?.[0]?.source_url) {
    return "";
  }

  const picture =
    src?.data?.[0]?._embedded?.["wp:featuredmedia"]?.[0]?.source_url;

  return picture;
};

export default getContactPicture;
